import AddEngagingModule from '../CreatingCourseModulePageContainer/AddEngagingModule/AddEngagingModule'
import CourseCreationLayout from '../../Components/CourseCreationLayout/CourseCreationLayout'

const AddEngagingLessonContent = () => {
  return (
    <CourseCreationLayout>
      <AddEngagingModule />
    </CourseCreationLayout>
  )
}

export default AddEngagingLessonContent

import CourseCreationLayout from '../../Components/CourseCreationLayout/CourseCreationLayout'
import CourseVideo from '../CourseVideo/CourseVideo'

type AddVideoContentTypes = { edit?: boolean }

const AddVideoContent = ({ edit }: AddVideoContentTypes) => {
  return (
    <CourseCreationLayout>
      <CourseVideo edit={edit} />
    </CourseCreationLayout>
  )
}

export default AddVideoContent

import React from 'react'
import Layout from '../Components/Layout/Layout'
import StudentDetailsMain from '../Containers/StudentDetailsMain/StudentDetailsMain'

const StudentDetailsPage = () => {
    return (
        <Layout>
            <StudentDetailsMain />
        </Layout>
    )
}

export default StudentDetailsPage

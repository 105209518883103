import moment from 'moment'
import React from 'react'
import Button from '../../Components/Button/Button'
import Loader from '../../Components/Loader/Loader'
import { copyToClipboard } from '../../HelperFunctions/copyToClipboard'
import { useTutorScheduleDetails } from '../../Hooks/useSchedule'
import classes from './ViewSessionModal.module.css'

type ViewSessionModalTypes = {
  id: string
  onClick1?: () => void
  onClick2?: () => void
  onClick3?: () => void
}

const ViewSessionModal = ({
  id,
  onClick1,
  onClick2,
  onClick3,
}: ViewSessionModalTypes) => {
  // Requests
  const { isLoading, data } = useTutorScheduleDetails(id)
  const scheduleDetails = data?.data

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={classes.container}>
      <h4>Meeting details</h4>

      <div className={classes.info}>
        <h5>Meeting title</h5>
        <p>{scheduleDetails?.session_subject}</p>
      </div>

      <div className={classes.info}>
        <h5>Meeting date</h5>
        <p>
          {moment(scheduleDetails?.start_time).format('dddd, DD MMMM YYYY')}
        </p>
      </div>

      <div className={classes.info}>
        <h5>Meeting time</h5>
        <p>
          {moment(scheduleDetails?.start_time).format('HH:MMa')} -{' '}
          {moment(scheduleDetails?.end_time).format('HH:MMa')}
        </p>
      </div>

      <div className={classes.info}>
        <h5>Meeting link</h5>
        <p>
          <span>{scheduleDetails?.google_meet_link}</span>
          <span
            onClick={() => {
              copyToClipboard(scheduleDetails?.google_meet_link)
            }}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 18.5C8.45 18.5 7.97917 18.3042 7.5875 17.9125C7.19583 17.5208 7 17.05 7 16.5V4.5C7 3.95 7.19583 3.47917 7.5875 3.0875C7.97917 2.69583 8.45 2.5 9 2.5H18C18.55 2.5 19.0208 2.69583 19.4125 3.0875C19.8042 3.47917 20 3.95 20 4.5V16.5C20 17.05 19.8042 17.5208 19.4125 17.9125C19.0208 18.3042 18.55 18.5 18 18.5H9ZM9 16.5H18V4.5H9V16.5ZM5 22.5C4.45 22.5 3.97917 22.3042 3.5875 21.9125C3.19583 21.5208 3 21.05 3 20.5V6.5H5V20.5H16V22.5H5Z"
                fill="#664EFE"
              />
            </svg>
            <span>Copy link</span>
          </span>
        </p>
      </div>

      <div className={classes.info}>
        <h5>Meeting attendees</h5>
        <p>Introduction to Management</p>
      </div>

      <div className={classes.buttonSection}>
        <Button type="null" onClick={onClick1}>
          Cancel session
        </Button>
        <Button type="secondary" onClick={onClick2}>
          Reschdule session
        </Button>
        <Button onClick={onClick3}>Close</Button>
      </div>
    </div>
  )
}

export default ViewSessionModal

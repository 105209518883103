import CourseCreationLayout from '../../Components/CourseCreationLayout/CourseCreationLayout'
import CourseReading from '../CourseReading/CourseReading'

type AddReadingContentTypes = {
  edit?: boolean
}

const AddReadingContent = ({ edit }: AddReadingContentTypes) => {
  return (
    <CourseCreationLayout>
      <CourseReading edit={edit} />
    </CourseCreationLayout>
  )
}

export default AddReadingContent

import classes from './OverallCourseRating.module.css'
import StarRating from '../../Components/StarRating/StarRating'
import ProgressBar from '../../Components/ProgressBar/ProgressBar'
import { useTutorCourseOverallRating } from '../../Hooks/useTutorCourses'
import { useEffect, useState } from 'react'
import Loader from '../../Components/Loader/Loader'

type OverallCourseRatingTypes = {
  tutorCourses: any
}

const OverallCourseRating = ({ tutorCourses }: OverallCourseRatingTypes) => {
  const [ratings, setRatings] = useState([
    {
      starRating: 5,
      progressPercent: 10,
    },
    {
      starRating: 4,
      progressPercent: 10,
    },
    {
      starRating: 3,
      progressPercent: 0,
    },
    {
      starRating: 2,
      progressPercent: 0,
    },
    {
      starRating: 1,
      progressPercent: 0,
    },
  ])

  const { isLoading, data } = useTutorCourseOverallRating(
    tutorCourses[0]?.course?.id
  )
  const overallRating = data?.data

  // Effects
  useEffect(() => {
    if (overallRating) {
      setRatings((prevState) => {
        const updatedState = [...prevState]
        for (let i = 0; i < ratings.length; i++) {
          updatedState[i].progressPercent =
            overallRating?.ratingBreakdown[i + 1]
        }
        return updatedState
      })
    }
  }, [overallRating])

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={classes.container}>
      <div className={classes.listNav}>
        <h4>Overall Course Rating</h4>
        <span>This week</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19 9L12 16L5 9"
            stroke="#2E2E2E"
            strokeWidth="2"
            stroke-linecap="round"
          ></path>
        </svg>
      </div>
      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <h4>{overallRating?.overallRating}</h4>
          <StarRating
            totalStars={5}
            initialRating={Math.floor(overallRating?.overallRating)}
            editable={false}
            width={25}
            height={25}
          />
          <p>Overall course ratings</p>
        </div>

        {ratings.map((data, i) => {
          return (
            <div key={i} className={classes.contentBody}>
              <StarRating
                totalStars={5}
                initialRating={data.starRating}
                editable={false}
                width={15}
                height={15}
              />
              <ProgressBar
                percentage={data.progressPercent}
                color="#fff"
                secondaryColor="#664EFE"
                primaryColor="#CAC2FE"
              />
              <span>{data.progressPercent}%</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default OverallCourseRating

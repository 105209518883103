import CourseCreationLayout from '../../Components/CourseCreationLayout/CourseCreationLayout'
import CoursePresentation from '../CoursePresentation/CoursePresentation'
const AddPresentation = () => {
  return (
    <CourseCreationLayout>
      <CoursePresentation />
    </CourseCreationLayout>
  )
}

export default AddPresentation

import { backend_url } from '../Utilities/global'
import useGetHook from './useGetHook'

export const useCurriculum = (id: string) => {
  const url = id ? `${backend_url}/api/ise/v1/tutors/${id}/curriculum` : null

  return useGetHook(url, {
    revalidateOnFocus: false,
  })
}

import classes from './ActionsModal.module.css'

type ActionsModalProps = {
  onClick: () => void
  onClick2: () => void
  onClick3: () => void
}

const ActionsModal = ({ onClick, onClick2, onClick3 }: ActionsModalProps) => {
  const modalOptions = [
    {
      action: onClick,
      title: 'View session details',
      svg: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
            stroke="#2E2E2E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.45825 12C3.73253 7.94288 7.52281 5 12.0004 5C16.4781 5 20.2684 7.94291 21.5426 12C20.2684 16.0571 16.4781 19 12.0005 19C7.52281 19 3.73251 16.0571 2.45825 12Z"
            stroke="#2E2E2E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      action: onClick2,
      title: 'Reschedule session',
      svg: (
        <svg
          width="19"
          height="17"
          viewBox="0 0 19 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.9166 8.08333L16.2505 9.75L14.5833 8.08333M16.4542 9.33333C16.4845 9.05972 16.5 8.78167 16.5 8.5C16.5 4.35786 13.1421 1 9 1C4.85786 1 1.5 4.35786 1.5 8.5C1.5 12.6421 4.85786 16 9 16C11.3561 16 13.4584 14.9136 14.8333 13.2144M9 4.33333V8.5L11.5 10.1667"
            stroke="#2E2E2E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },

    {
      action: onClick3,
      title: 'Cancel session',
      svg: (
        <svg
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.33333 10.1667L9 8.5M9 8.5L10.6667 6.83333M9 8.5L7.33333 6.83333M9 8.5L10.6667 10.1667M16.5 8.5C16.5 12.6421 13.1421 16 9 16C4.85786 16 1.5 12.6421 1.5 8.5C1.5 4.35786 4.85786 1 9 1C13.1421 1 16.5 4.35786 16.5 8.5Z"
            stroke="#2E2E2E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  ]
  return (
    <div className={classes.container}>
      {modalOptions.map((data, i) => (
        <>
          <div key={i} onClick={data.action}>
            {data.svg}
            <span>{data.title}</span>
          </div>
        </>
      ))}
    </div>
  )
}

export default ActionsModal

import CourseCreationLayout from '../../Components/CourseCreationLayout/CourseCreationLayout'
import AddNewWeekModule from '../CreatingCourseModulePageContainer/AddNewWeekModule/AddNewWeekModule'

type CreatingCourseModuleWeekPageContainerTypes = {
  edit?: boolean
}

const CreatingCourseModuleWeekPageContainer = ({
  edit,
}: CreatingCourseModuleWeekPageContainerTypes) => {
  return (
    <CourseCreationLayout edit={edit}>
      <AddNewWeekModule edit={edit} />
    </CourseCreationLayout>
  )
}

export default CreatingCourseModuleWeekPageContainer

import Button from '../Button/Button'
import classes from './DeleteModalBody.module.css'
import deleteImage from '../../Assets/Images/deleteImage.svg'
import { requestType } from '../../Context/AuthUserContext'

type DeleteModalBodyType = {
  header: string
  caption: string
  gif?: string
  onClick1?: () => void
  onClick2?: () => void
  buttonOneText: string
  buttonTwoText: string
  requestState?: requestType
}

const DeleteModalBody = ({
  header,
  caption,
  gif,
  onClick1,
  onClick2,
  buttonOneText,
  buttonTwoText,
  requestState,
}: DeleteModalBodyType) => {
  return (
    <div className={classes.container}>
      <img src={gif || deleteImage} alt="Deletion confirm" />
      <h2> {header || 'Successfully'} </h2>
      <p>{caption}</p>
      <div className={classes.buttonSection}>
        <Button onClick={onClick1} type="bordered">
          {buttonOneText || 'Cancel'}
        </Button>
        <Button
          onClick={onClick2}
          type="delete"
          loading={requestState?.isLoading}
        >
          {buttonTwoText || 'Okay'}
        </Button>
      </div>
    </div>
  )
}

export default DeleteModalBody

import Calender from '../../Components/Calender/Calender'
import CalendarComponent from '../../Components/CalenderComponent/CalenderComponent'
import { transformAppointments } from '../../HelperFunctions/transformAppointments'
import SchedulesSessionModules from '../SchedulesSessionModules/SchedulesSessionModules'
import classes from './SchedulesModules.module.css'

type SchedulesModulesType = {
  schedule: any
}

const SchedulesModules = ({ schedule }: SchedulesModulesType) => {
  const transformedSchedule = transformAppointments(schedule)
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <div>
          <h4>Schedules</h4>
        </div>
      </div>
      <div className={classes.scrollContainer}>
        <div className={classes.schedules}>
          <CalendarComponent tasks={transformedSchedule} schedule />
          <SchedulesSessionModules schedule={schedule} />
        </div>
      </div>
    </section>
  )
}

export default SchedulesModules

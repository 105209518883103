import { backend_url } from '../Utilities/global'
import useGetHook from './useGetHook'

export const useStudents = (pageNumber: string) => {
  return useGetHook(
    `${backend_url}/api/ise/v1/tutors/enrolled-students?page=${pageNumber}`,
    { revalidateOnFocus: false }
  )
}

export const useStudentsById = (id: string) => {
  const url = id
    ? `${backend_url}/api/ise/v1/tutors/enrolled-student/${id}`
    : null
  return useGetHook(url, {
    revalidateOnFocus: false,
  })
}

export const useStudentGrades = (id: string) => {
  const url = id
    ? `${backend_url}/api/ise/v1/tutors/student_grades/${id}`
    : null

  return useGetHook(url, {
    revalidateOnFocus: false,
  })
}

export const useStudentFeedback = (id: string) => {
  const url = id
    ? `${backend_url}/api/ise/v1/tutors/student_survey/${id}`
    : null

  return useGetHook(url, {
    revalidateOnFocus: false,
  })
}

export const useStudentQuiz = (id: string) => {
  const url = id
    ? `${backend_url}/api/ise/v1/tutors/student_quizzes/${id}`
    : null

  return useGetHook(url, {
    revalidateOnFocus: false,
  })
}

export const useStudentAssignments = () => {
  return useGetHook(`${backend_url}/api/ise/v1/tutors/student_assignments`, {
    revalidateOnFocus: false,
  })
}

export const useStudentAssignmentsById = (id: string) => {
  const url = id
    ? `${backend_url}/api/ise/v1/tutors/student_assignments/${id}`
    : null

  return useGetHook(url, {
    revalidateOnFocus: false,
  })
}

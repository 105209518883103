import Layout from "../Components/Layout/Layout";
import SupportContainer from "../Containers/SupportContainer/SupportContainer";

const TutorSupportPage = () => {
  return (
    <Layout>
      <SupportContainer />
    </Layout>
  );
};

export default TutorSupportPage;

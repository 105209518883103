import Layout from '../Components/Layout/Layout'
import AssignmentPageContainer from '../Containers/AssignmentPageContainer/AssignmentPageContainer'

const AssignmentPage = () => {
  return (
    <Layout>
      <AssignmentPageContainer />
    </Layout>
  )
}

export default AssignmentPage

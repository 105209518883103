import { useState } from 'react'
import EmptyTabComponent from '../../Components/EmptyTabComponent/EmptyTabComponent'
import SectionsNav from '../../Components/SectionsNav/SectionsNav'
import classes from './SchedulesSessionModules.module.css'
import noOvervue from '../../Assets/Images/noOvervue.svg'
import LiveClassSessions from '../ScheduleContainer/ScheduleContainer'
import OneOnOneCallSessions from '../OneOnOneCallSessions/OneOnOneCallSessions'
import ScheduleContainer from '../ScheduleContainer/ScheduleContainer'

type SchedulesSessionModulesType = {
  schedule: any
}

const SchedulesSessionModules = ({ schedule }: SchedulesSessionModulesType) => {
  // States
  const [navItems, setNavItems] = useState<any[]>([
    {
      title: 'Live class Sessions',
      isActive: true,

      activeNullStateComponent: (
        <EmptyTabComponent
          image={noOvervue}
          firstParagraph="You have no schedule at the moment."
          secondParagraph=""
          route="/courses"
          buttontext="Explore classes"
          showButton={false}
        />
      ),
    },
    {
      title: 'One-on-one call sessions',
      isActive: false,

      activeNullStateComponent: (
        <EmptyTabComponent
          image={noOvervue}
          firstParagraph="You have no schedule at the moment."
          secondParagraph=""
          route="/schedule"
          buttontext="Schedule Appointment"
          showButton={false}
        />
      ),
    },
  ])

  const activeCOmponent = navItems.find((data) => {
    return data.isActive
  })
  return (
    <div className={classes.container}>
      <SectionsNav navItems={navItems} setNavItems={setNavItems} />
      {schedule?.length ? (
        <div className={classes.schedule}>
          {navItems[0]?.isActive && (
            <ScheduleContainer
              schedule={schedule?.filter((data: any) => {
                return data?.session_type === 'live-class'
              })}
            />
          )}
          {navItems[1]?.isActive && (
            <ScheduleContainer
              schedule={schedule?.filter((data: any) => {
                return data?.session_type !== 'live-class'
              })}
            />
          )}
        </div>
      ) : (
        <EmptyTabComponent
          image={noOvervue}
          firstParagraph="You have no schedule at the moment."
          secondParagraph=""
          route="/courses"
          buttontext="Explore classes"
          showButton={false}
        />
      )}
    </div>
  )
}

export default SchedulesSessionModules

import classes from './DeleteSuccessfulModalBody.module.css'
import success from '../../Assets/Gifs/success.gif'
import Button from '../Button/Button'

type DeleteDisclaimerModalBodyProps = {
  onClick: () => void
  header: string
  caption: string
}

const DeleteSuccessfulModalBody = ({
  onClick,
  header,
  caption,
}: DeleteDisclaimerModalBodyProps) => {
  return (
    <div className={classes.modalContainer}>
      <div className={classes.modalInnerContainer2}>
        <img src={success} alt="Success" />
        <h4>{header}</h4>
        <p>{caption}</p>
        <Button onClick={onClick}>Close</Button>
      </div>
    </div>
  )
}

export default DeleteSuccessfulModalBody

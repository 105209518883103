import { availabilityType } from '../Utilities/types'

function getDayOfWeekShort(dateString: string): string {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const date = new Date(dateString)
  return daysOfWeek[date.getUTCDay()] // Get the day of the week
}

function formatDate(dateString: string): string {
  const date = new Date(dateString)
  const day = String(date.getUTCDate()).padStart(2, '0') // Get day and pad with 0 if needed
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Get month and pad with 0 if needed
  const year = date.getUTCFullYear()
  return `${day}-${month}-${year}` // Return in DD-MM-YYYY format
}

export function transformAppointments(appointments: any): availabilityType {
  const availabilityMap: {
    [key: string]: { startTime: string; endTime: string }[]
  } = {}

  appointments?.forEach((appointment: any) => {
    const appointmentDate = new Date(appointment.start_time)
      .toISOString()
      .split('T')[0] // Extract the date part (YYYY-MM-DD)

    if (!availabilityMap[appointmentDate]) {
      availabilityMap[appointmentDate] = []
    }

    availabilityMap[appointmentDate].push({
      startTime: appointment.start_time,
      endTime: appointment.end_time,
    })
  })

  return Object.keys(availabilityMap).map((date) => ({
    day: getDayOfWeekShort(date), // Get the short day of the week
    date: formatDate(date), // Format the date as DD-MM-YYYY
    availableTimes: availabilityMap[date],
  }))
}

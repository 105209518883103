import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { mutate } from 'swr'
import Button from '../../Components/Button/Button'
import Checkbox from '../../Components/Checkbox/Checkbox'
import Input from '../../Components/Input/Input'
import Loader from '../../Components/Loader/Loader'
import { ScheduleContext } from '../../Context/ScheduleContext'
import { inputChangeHandler } from '../../HelperFunctions/inputChangeHandler'
import { useTutorScheduleDetails } from '../../Hooks/useSchedule'
import { useTutorCohort } from '../../Hooks/useTutorCourses'
import { backend_url } from '../../Utilities/global'
import classes from './CreateAMeeting.module.css'

type CreateAMeetingType = {
  onClick: () => void
  year: string
  month: string
  id?: string
}

const CreateAMeeting = ({ onClick, year, month, id }: CreateAMeetingType) => {
  // Context
  const { createMeeting, requestState, rescheduleMeeting } =
    useContext(ScheduleContext)

  // State
  const [createMeetingData, setCreateMeetingData] = useState({
    date: '',
    startTime: '',
    endTime: '',
    sessionSubject: '',
  })
  const [dateAndTime, setDateAndTime] = useState('')

  // Request
  const { isLoading, data } = useTutorCohort()
  const { isLoading: scheduleDetailsIsLoading, data: scheduleDetailsData } =
    useTutorScheduleDetails(id as string)

  // Utils
  const cohortData = data?.data
  const schedule = scheduleDetailsData?.data

  // Effects
  useEffect(() => {
    if (dateAndTime) {
      const startTime = moment(new Date(dateAndTime).getTime()).format('HH:mm')
      const date = moment(new Date(dateAndTime)).format('yyyy-MM-DD')

      setCreateMeetingData((prevState) => {
        return { ...prevState, startTime, date }
      })
    }
  }, [dateAndTime])

  useEffect(() => {
    if (requestState?.data && requestState?.id === 'create-meeting') {
      mutate(
        `${backend_url}/api/ise/v1/booking-session?year=${year}&month=${month}`
      )
    }
  }, [requestState?.data])

  useEffect(() => {
    if (id) {
      mutate(`${backend_url}/api/ise/v1/booking-session/${id}`)
    }
  }, [])

  useEffect(() => {
    if (schedule) {
      setCreateMeetingData({
        date: moment(schedule?.start_time).format('YYYY-MM-DD'),
        startTime: moment(schedule?.start_time).format('hh:mm'),
        endTime: moment(schedule?.end_time).format('hh:mm'),
        sessionSubject: schedule?.session_subject,
      })
      setDateAndTime(moment(schedule?.start_time).format('YYYY-MM-DDTHH:mm'))
    }
  }, [schedule])

  if (isLoading || scheduleDetailsIsLoading) {
    return <Loader />
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h2>{id ? 'Reschedule' : 'Create'} a meeting</h2>
        <p>Create a new meeting for a general live class session</p>
      </div>

      <div className={classes.body}>
        <Input
          label="Add meeting title"
          isRequired
          placeholder="Type your message here..."
          name="sessionSubject"
          value={createMeetingData.sessionSubject}
          onChange={(e) => inputChangeHandler(e, setCreateMeetingData)}
        />

        <Input
          label="Set meeting time"
          isRequired
          placeholder="Type your message here..."
          tip="Event time zone is automatically set to WAT (GMT+1:00) "
          type="datetime-local"
          value={dateAndTime}
          onChange={(e) => inputChangeHandler(e, setDateAndTime, true)}
        />

        <Input
          label="Set meeting end time"
          isRequired
          placeholder="Type your message here..."
          tip="Event time zone is automatically set to WAT (GMT+1:00) "
          type="time"
          name="endTime"
          value={createMeetingData.endTime}
          onChange={(e) => inputChangeHandler(e, setCreateMeetingData)}
        />

        <div className={classes.attendance}>
          <h4>Student attendance</h4>
          <p>Choose the cohort who would be attending this meeting</p>
          {cohortData?.map((data: any, index: number) => {
            return (
              <div key={index}>
                <Checkbox isChecked={true} disabled={true} />
                <label>{data?.name}</label>
              </div>
            )
          })}
        </div>
      </div>

      <div className={classes.buttonSection}>
        <Button type="secondary" onClick={onClick}>
          Cancel
        </Button>
        <Button
          loading={requestState?.isLoading}
          onClick={() => {
            if (id) {
              rescheduleMeeting(createMeetingData, id)
            } else {
              createMeeting(createMeetingData)
            }
          }}
          disabled={
            !createMeetingData.date ||
            !createMeetingData.endTime ||
            !createMeetingData.sessionSubject ||
            !createMeetingData.startTime
          }
        >
          {id ? 'Reschedule' : 'Create'} meeting
        </Button>
      </div>
    </div>
  )
}

export default CreateAMeeting

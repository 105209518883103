import Layout from '../Components/Layout/Layout'
import CoursesCurriculumSetupContainer from '../Containers/CoursesCurriculumSetupContainer/CoursesCurriculumSetupContainer'

const CoursesCurriculum = () => {
  return (
    <Layout>
      <CoursesCurriculumSetupContainer />
    </Layout>
  )
}

export default CoursesCurriculum

import React from 'react'
import Layout from '../Components/Layout/Layout'
import CoursesModules from '../Containers/CoursesModules/CoursesModules'

const CoursesPage = () => {
  return (
    <Layout>
      <CoursesModules />
    </Layout>
  )
}

export default CoursesPage

import { capitalize } from '@mui/material'
import moment from 'moment'
import { useEffect } from 'react'
import { mutate } from 'swr'
import Button from '../../../Components/Button/Button'
import Loader from '../../../Components/Loader/Loader'
import { useStudentAssignmentsById } from '../../../Hooks/useStudents'
import { backend_url } from '../../../Utilities/global'
import classes from './AssignmentSummaryModal.module.css'

type AssignmentSummaryModalProps = {
  onClick: () => void
  activeId?: string
}

const AssignmentSummaryModal = ({
  onClick,
  activeId,
}: AssignmentSummaryModalProps) => {
  // Requests
  const { isLoading, data } = useStudentAssignmentsById(activeId as string)
  const studentAssignmentData = data?.data

  //   Effects
  useEffect(() => {
    if (activeId) {
      mutate(`${backend_url}/api/ise/v1/tutors/student_assignments/${activeId}`)
    }
  }, [activeId])

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h4>Assignment summary</h4>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          onClick={onClick}
        >
          <path
            d="M12 36L36 12M12 12L36 36"
            stroke="#2E2E2E"
            strokeWidth="2"
            stroke-linecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <div className={classes.detailContent}>
        <div className={classes.detailHeader}>
          <span>Student name</span>
          <span>Lesson tile</span>
          <span>Module title</span>
          <span>Submission date</span>
          <span>Submitted file</span>
          <span>Submission status</span>
          <span>Grade status</span>
          <span>Grade score</span>
          <span>Message</span>
          <span>Feedback</span>
        </div>
        <div className={classes.detailBody}>
          <span>{studentAssignmentData?.full_name}</span>
          <span>{studentAssignmentData?.title}</span>
          <span>{studentAssignmentData?.module_title} </span>
          <span>
            {moment(studentAssignmentData?.submitted_date).format(
              'DD MMM, YYYY HH:MM'
            )}
          </span>
          <span>{studentAssignmentData?.uploaded_file}</span>
          <span>{capitalize(studentAssignmentData?.status || '')}</span>
          <span>Grade</span>
          <span>{studentAssignmentData?.grade}</span>
          <span>No message</span>
          <span
            dangerouslySetInnerHTML={{
              __html: studentAssignmentData?.instruction,
            }}
          ></span>
        </div>
      </div>

      <div className={classes.footer}>
        <Button onClick={onClick} type="primary">
          <span>Done</span>
        </Button>
      </div>
    </div>
  )
}

export default AssignmentSummaryModal

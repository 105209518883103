import { useEffect, useState } from 'react'
import Input from '../../Components/Input/Input'
import classes from './CourseVideo.module.css'
import SectionsNav4 from '../../Components/SectionsNav4/SectionsNav4'
import VideoUploadComponent from './VideoUploadComponent'
import EmbedVideoComponent from './EmbedVideoComponent'
import { uploadVideoData } from '../../Utilities/types'
import { inputChangeHandler } from '../../HelperFunctions/inputChangeHandler'
import { useLessonById } from '../../Hooks/useTutorCourses'
import { useParams } from 'react-router-dom'
import Loader from '../../Components/Loader/Loader'
import { mutate } from 'swr'
import { backend_url } from '../../Utilities/global'

type CourseVideoTypes = {
  edit?: boolean
}

const CourseVideo = ({ edit }: CourseVideoTypes) => {
  // States
  const [navItems, setNavItems] = useState<any[]>([
    {
      title: 'Upload video',
      isActive: true,
    },
    {
      title: 'Embed video',
      isActive: false,
    },
  ])

  // Router
  const { lessonId } = useParams()

  //   States
  const [uploadVideoData, setUploadVideoData] = useState<uploadVideoData>({
    title: '',
    embed_url: '',
    embed_description: '',
    video_file: null,
    thumbnail: null,
  })
  const [uploadVideoDataFormData, setUploadVideoDataFormData] = useState(
    new FormData()
  )

  // Requests
  const { isLoading, data } = useLessonById(lessonId as string, 'video')
  const content = data?.data

  //   Effects
  useEffect(() => {
    const formData = new FormData()
    formData.append('title', uploadVideoData.title)
    formData.append('embed_url', uploadVideoData.embed_url)
    formData.append('embed_description', uploadVideoData.embed_description)
    formData.append('video_file', uploadVideoData?.video_file as File)
    formData.append('thumbnail', uploadVideoData.thumbnail as File)

    setUploadVideoDataFormData(formData)
  }, [uploadVideoData])

  useEffect(() => {
    if (edit) {
      mutate(`${backend_url}/api/ise/v1/video/${lessonId as string}`)
    }
  }, [])

  useEffect(() => {
    if (content) {
      setUploadVideoData({
        title: content?.title,
        embed_url: content?.video_url,
        embed_description: content?.description,
        video_file: null,
        thumbnail: content?.thumbnail,
      })
    }
  }, [content])

  if (isLoading) {
    return (
      <div className={classes.container}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <h1>{uploadVideoData.title || 'Untitled lesson'}</h1>
      <div className={classes.addLesson}>
        <Input
          isRequired
          label="Add lesson title"
          placeholder="Untitled lesson"
          errorMessage="Please enter a valid lesson name"
          name="title"
          value={uploadVideoData.title}
          onChange={(e) => {
            inputChangeHandler(e, setUploadVideoData)
          }}
        />
      </div>
      <div className={`${classes.addLesson} ${classes.body}`}>
        <section className={classes.subContainer}>
          <div className={classes.sectionsNavSection}>
            <h2>Add Video</h2>
            <SectionsNav4 navItems={navItems} setNavItems={setNavItems} />
          </div>
          <div>
            {navItems[0].isActive && (
              <VideoUploadComponent
                uploadVideoData={uploadVideoData}
                setUploadVideoData={setUploadVideoData}
                uploadVideoDataFormData={uploadVideoDataFormData}
                edit={edit}
              />
            )}
            {navItems[1].isActive && (
              <EmbedVideoComponent
                uploadVideoData={uploadVideoData}
                setUploadVideoData={setUploadVideoData}
                uploadVideoDataFormData={uploadVideoDataFormData}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  )
}

export default CourseVideo

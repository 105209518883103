import AddModuleDetails from './AddModulesDetails/AddModuleDetails'
import CourseCreationLayout from '../../Components/CourseCreationLayout/CourseCreationLayout'

type CreatingCourseModulePageContainertype = {
  edit: boolean
}

const CreatingCourseModulePageContainer = ({
  edit,
}: CreatingCourseModulePageContainertype) => {
  return (
    <CourseCreationLayout>
      <AddModuleDetails edit={edit} />
    </CourseCreationLayout>
  )
}

export default CreatingCourseModulePageContainer

import CreatingCourseModulePageContainer from '../Containers/CreatingCourseModulePageContainer/CreatingCourseModulePageContainer'

type CreatingCourseModulePageType = {
  edit?: boolean
}

const CreatingCourseModulePage = ({ edit }: CreatingCourseModulePageType) => {
  return <CreatingCourseModulePageContainer edit={edit as boolean} />
}

export default CreatingCourseModulePage

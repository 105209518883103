import Layout from '../Components/Layout/Layout'
import SchedulePageContainer from '../Containers/SchedulePageContainer/SchedulePageContainer'

const SchedulePage = () => {
  return (
    <Layout>
      <SchedulePageContainer />
    </Layout>
  )
}

export default SchedulePage

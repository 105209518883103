import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import classes from './ScheduleCard.module.css'
import ellipses from '../../Assets/Images/ellipses.svg'
import yellowDot from '../../Assets/Images/yellowDot.svg'
import googleMeet from '../../Assets/Images/googleMeet.svg'
import ActionsModal from './ActionsModal/ActionsModal'
import moment from 'moment'

interface ScheduleCardProps {
  index: number
  startTime: string
  endTime: string
  title: string
  link: string
  onClick1?: () => void
  onClick2?: () => void
  onClick3?: () => void
  setActiveId?: Dispatch<SetStateAction<string>>
  id?: number
}

const ScheduleCard = ({
  index,
  startTime,
  endTime,
  title,
  link,
  onClick1,
  id,
  setActiveId,
  onClick2,
  onClick3,
}: ScheduleCardProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const modalRef = useRef<HTMLDivElement>(null)

  const handleEllipsesClick = (id: string) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index))
    if (setActiveId) {
      setActiveId(id)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setActiveIndex(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <li className={classes.list}>
      <div className={classes.top}>
        <img src={yellowDot} alt="" />
        <span>
          {moment(startTime)?.format('hh:mma')} -{' '}
          {moment(endTime)?.format('hh:mma')}
        </span>
        <div className={classes.ellipses}>
          <img
            src={ellipses}
            alt="more options"
            onClick={() => {
              handleEllipsesClick(String(id))
            }}
          />
          {activeIndex === index && (
            <div ref={modalRef} className={classes.popover}>
              <ActionsModal
                onClick={() => {
                  if (onClick1) {
                    onClick1()
                  }
                }}
                onClick2={() => {
                  if (onClick2) {
                    onClick2()
                  }
                }}
                onClick3={() => {
                  if (onClick3) {
                    onClick3()
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      <h4>{title}</h4>
      <div className={classes.bottom}>
        <img src={googleMeet} alt="Meeting Type" />
        <a href={link} target="_blank" rel="noopener noreferrer">
          Join Now
        </a>
      </div>
    </li>
  )
}

export default ScheduleCard

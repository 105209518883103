import OverallCourseRating from '../OverallCourseRating/OverallCourseRating'
import StudentEngagement from '../StudentEngagement/StudentEngagement'
import classes from './StudentParticipationandSatisfaction.module.css'

type StudentParticipationandSatisfactionTypes = {
  tutorCourses: any
}

const StudentParticipationandSatisfaction = ({
  tutorCourses,
}: StudentParticipationandSatisfactionTypes) => {
  return (
    <section className={classes.container}>
      <div className={classes.subContainer}>
        <StudentEngagement />
        <OverallCourseRating tutorCourses={tutorCourses} />
      </div>
    </section>
  )
}

export default StudentParticipationandSatisfaction

import classes from './EngagementInsightsHub.module.css'
import InsightTab from '../../Components/InsightTab/InsightTab'
import { useEffect, useState } from 'react'

type EngagementInsightsHubTypes = {
  tutorCourses: any
}

const EngagementInsightsHub = ({
  tutorCourses,
}: EngagementInsightsHubTypes) => {
  //   States
  const [tutorCoursesState, setTutorCoursesState] = useState({
    courses: 0,
    published: 0,
    upcoming: 0,
  })

  // Egffects
  useEffect(() => {
    if (tutorCourses?.length) {
      setTutorCoursesState((prevState) => {
        const updatedState = { ...prevState }
        updatedState.courses = tutorCourses?.length
        updatedState.published = tutorCourses?.filter(
          (data: any) => data?.status === 'published'
        )?.length
        updatedState.upcoming = tutorCourses?.filter(
          (data: any) => data?.status === 'upcoming'
        )?.length
        return updatedState
      })
    }
  }, [tutorCourses])

  return (
    <section className={classes.container}>
      <div>
        <InsightTab
          totalNumber={tutorCoursesState?.courses}
          title="Total course assigned"
        />
        <InsightTab
          totalNumber={tutorCoursesState?.published}
          title="Published course(s)"
        />
        <InsightTab
          totalNumber={tutorCoursesState?.upcoming}
          title="Upcoming sessions"
        />
      </div>
    </section>
  )
}

export default EngagementInsightsHub

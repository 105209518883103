import { backend_url } from '../Utilities/global'
import useGetHook from './useGetHook'

const useTutorCourses = () => {
  return useGetHook(`${backend_url}/api/ise/v1/tutors/my-courses`, {
    revalidateOnFocus: false,
  })
}

export const useCourseById = (id: string) => {
  return useGetHook(`${backend_url}/api/ise/v1/courses/${id}`, {
    revalidateOnFocus: false,
  })
}

export const useModuleById = (id: string | null) => {
  const url = id
    ? `${backend_url}/api/ise/v1/course-modules/${id as string}`
    : null
  return useGetHook(url, {
    revalidateOnFocus: false,
  })
}

export const useTutorCohort = () => {
  return useGetHook(`${backend_url}/api/ise/v1/tutors/my-courses`, {
    revalidateOnFocus: false,
  })
}

export const useTutorCourseReviews = (id: string) => {
  const url = id
    ? `${backend_url}/api/ise/v1/tutors/course/${id}/reviews`
    : null
  return useGetHook(url, {
    revalidateOnFocus: false,
  })
}

export const useTutorCourseOverallRating = (id: string) => {
  const url = id
    ? `${backend_url}/api/ise/v1/tutors/course/${id}/overall-rating`
    : null
  return useGetHook(url, {
    revalidateOnFocus: false,
  })
}

export const useWeekById = (id: string | null) => {
  const url = id
    ? `${backend_url}/api/ise/v1/course-weeks/${id as string}`
    : null
  return useGetHook(url, {
    revalidateOnFocus: false,
  })
}

export const useLessonById = (id: string, type: string) => {
  const url = id ? `${backend_url}/api/ise/v1/${type}/${id}` : null
  return useGetHook(url, {
    revalidateOnFocus: false,
  })
}

export default useTutorCourses

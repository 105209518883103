import { useEffect, useState } from 'react'
import Input from '../../Components/Input/Input'
import classes from './CourseReading.module.css'
import SectionsNav4 from '../../Components/SectionsNav4/SectionsNav4'
import BulkUploadComponent from './BulkUploadComponent'
import BuildFromScratch from './BuildFromScratch'
import { uploadReadingData } from '../../Utilities/types'
import { inputChangeHandler } from '../../HelperFunctions/inputChangeHandler'
import { mutate } from 'swr'
import { backend_url } from '../../Utilities/global'
import { useParams } from 'react-router-dom'
import { useLessonById } from '../../Hooks/useTutorCourses'
import Loader from '../../Components/Loader/Loader'

type CourseReadingType = {
  edit?: boolean
}
const CourseReading = ({ edit }: CourseReadingType) => {
  // States
  const [navItems, setNavItems] = useState<any[]>([
    {
      title: 'Bulk Upload',
      isActive: true,
    },
    {
      title: 'Type content',
      isActive: false,
    },
  ])

  const [readingData, setReadingData] = useState<uploadReadingData>({
    title: '',
    reading_contents: null,
    file: null,
    resources: [],
  })

  // Formdata
  const [readingFormData, setReadingFormData] = useState(new FormData())

  // Router
  const { lessonId } = useParams()

  // Request
  const { isLoading, data } = useLessonById(lessonId as string, 'reading')
  const readingContent = data?.data

  // Effects
  useEffect(() => {
    const newFormData = new FormData()
    newFormData.append('title', readingData.title)
    if (readingData?.reading_contents) {
      newFormData.append(
        'reading_contents',
        readingData?.reading_contents as string
      )
    }

    newFormData.append('resources', readingData.resources?.toString())

    if (readingData.file) {
      Array.from(readingData?.file as FileList)?.forEach((file) => {
        newFormData.append('files', file)
      })
    }

    setReadingFormData(newFormData)

    // eslint-disable-next-line
  }, [readingData])

  useEffect(() => {
    if (edit) {
      mutate(`${backend_url}/api/ise/v1/reading/${lessonId as string}`)
    }
  }, [])

  useEffect(() => {
    if (readingContent) {
      setReadingData({
        title: readingContent?.title,
        reading_contents: readingContent?.reading_contents,
        file: null,
        resources: [],
      })
    }
  }, [readingContent])

  console.log(readingData)

  if (isLoading) {
    return (
      <div className={classes.container}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <h1>{readingData.title || 'Untitled lesson'}</h1>
      <div className={classes.addLesson}>
        <Input
          isRequired
          label="Add lesson title"
          placeholder="Untitled lesson"
          errorMessage="Please enter a valid lesson name"
          name="title"
          value={readingData.title}
          onChange={(e) => inputChangeHandler(e, setReadingData)}
        />
      </div>
      <div className={`${classes.addLesson} ${classes.body}`}>
        <section className={classes.subContainer}>
          <div className={classes.sectionsNavSection}>
            <h2>Add reading content</h2>
            <SectionsNav4 navItems={navItems} setNavItems={setNavItems} />
          </div>
          <div>
            {navItems[0].isActive && (
              <BulkUploadComponent
                readingData={readingData}
                setReadingData={setReadingData}
                readingFormData={readingFormData}
              />
            )}
            {navItems[1].isActive && (
              <BuildFromScratch
                readingData={readingData}
                setReadingData={setReadingData}
                readingFormData={readingFormData}
                edit={edit}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  )
}

export default CourseReading
